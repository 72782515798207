import * as d3 from "d3";

const HeatMapChart = (svgRef, {
    gridSize, width, height, animate = false
} = {}) => {
    const svg = d3.select(svgRef.current);
    const colors = ["#f7fbff", "#deebf7", "#c6dbef", "#9ecae1", "#6baed6", "#4292c6", "#2171b5", "#08519c", "#08306b"];
    const margin = ({ top: 20, right: 20, bottom: 20, left: 20 });
    const valueFormat = (value) => {
        let formattedValue = ''
        if (value >= 1000000) {
            formattedValue = (value / 1000000).toFixed(2) + 'M';
        } else if (value >= 1000) {
            formattedValue = Math.round(value / 1000) + 'K';
        } else {
            formattedValue = Math.round(value) + '';
        }
        return formattedValue
    }
    

    const update = (data, fillRGB = '153,47,255') => {
        // console.log(data);
        const hours = data.map((d) => d.month).filter((value, index, self) => { return self.indexOf(value) === index; });
        const days = data.map((d) => d.day).filter((value, index, self) => { return self.indexOf(value) === index; });;
        // const colorScale = d3.scaleQuantile()
        //     .domain([d3.min(data, function (d) { return +d.percentage; }), d3.max(data, function (d) { return +d.percentage; })])
        //     .range(colors)
        const max = d3.max(data, function (d) { return +d.value; });
        const min = d3.max(data, function (d) { return +d.value; });

        const colorScale = function(d, type = 'percentage'){
          if (type !== 'percentage') {
            const percentage = d/max;
            return "rgba(" + fillRGB + "," + percentage +")";
          } 
          return "rgba(" + fillRGB + "," + d / 100 + ")";
        }
        const w = width ? width : (gridSize * (hours.length + 1)) + 40;
        const h = height ? height : (gridSize * (days.length) + 1) + 40;

        svg.attr("width", w)
           .attr("height", h);

        const dayLabels = svg.selectAll(".dayLabel").data(days); //y axes ej: may 20
        
        dayLabels
            .enter().append("text")
            .text(function (d) { return d ; })
            .attr("x", 0)
            .attr("y", function (d, i) { return i * gridSize; })
            .attr("font-size", 10)
            .attr("fill", "#aaa")
            .attr("transform", "translate(0," + (margin.left + gridSize / 1.5) + ")")
            .attr("class", "dayLabel mono axis");

        dayLabels
            .text(function (d) { return d ; })
            .attr("x", 0)
            .attr("y", function (d, i) { return i * gridSize; })
            .attr("font-size", 10)
            .attr("fill", "#aaa")
            .attr("transform", "translate(0," + (margin.left + gridSize / 1.5) + ")")
            .attr("class", "dayLabel mono axis");

        const timeLabels = svg.selectAll(".timeLabel") //0,1,2,3..
            .data(hours)
            .enter().append("text")
            .text(function (d) { return d; })
            .attr("x", function (d, i) { return i * gridSize; })
            .attr("y", 0)
            .attr("font-size", 10)
            .attr("fill", "#aaa")
            .style("text-anchor", "middle")
            .attr("transform", "translate(" + (40 + gridSize / 2) + ", 12)")
            .attr("class", "timeLabel mono axis");

        const cards = svg.selectAll(".hour")
            .data(data, function (d) { return d.month + ':' + days.indexOf(d.day); });

        var currentCards = cards.enter().append("rect") //background box
            .attr("x", function (d) { return (d.type === 'percentage' ? d.month - 1 : d.month) * gridSize; })
            .attr("y", function (d) { return (days.indexOf(d.day)) * gridSize; })
            .attr("transform", "translate(40,20)")
            .attr("rx", 4)
            .attr("ry", 4)
            .attr("class", "hour bordered")
            .attr("width", gridSize)
            .attr("height", gridSize)
            .style("fill", colors[0])
            .style("stroke", "#F7F7F7")
            .style("stroke-width", 2)

            if(animate) {
                currentCards = currentCards.transition().duration(1000);
            }

            currentCards.style("fill", function (d) { return colorScale(d.value, d.type); });

        // cards.attr("x", function (d) { return (d.month) * gridSize; })
        //     .attr("y", function (d) { return (days.indexOf(d.day)) * gridSize; })
        //     .attr("transform", "translate(40,20)")
        //     .attr("rx", 4)
        //     .attr("ry", 4)
        //     .attr("class", "hour bordered")
        //     .attr("width", gridSize)
        //     .attr("height", gridSize)
        //     .style("fill", colors[0])
        //     .style("stroke", "#F7F7F7")
        //     .style("stroke-width", 2)
        //     .transition().duration(1000)
        //     .style("fill", function (d) { return colorScale(d.value); });

        cards.exit().remove();

        const cardText = svg.selectAll(".percentage")
            .data(data, function (d) { return d.month + ':' + days.indexOf(d.day); });

        cardText.enter().append("text")
            .text((d) => d.type === 'percentage' ? Math.round(d.value) + "%" : valueFormat(d.value))
            .attr("class", "percentage")
            .attr("x", function (d) { return (((d.type === 'percentage' ? d.month - 1 : d.month) * gridSize)) + (gridSize / 2) - 10; })
            .attr("y", function (d) { return ((days.indexOf(d.day)) * gridSize) + (gridSize / 2) + 5; })
            .attr("transform", "translate(40,20)")
            .attr("font-size", 10)
            .attr("fill", "black")
        
        cardText
            .text((d) => d.type === 'percentage' ? Math.round(d.value) + "%" : valueFormat(d.value))
            .attr("class", "percentage")
            .attr("x", function (d) { return (((d.type === 'percentage' ? d.month - 1 : d.month) * gridSize)) + (gridSize / 2) - 10; })
            .attr("y", function (d) { return ((days.indexOf(d.day)) * gridSize) + (gridSize / 2) + 5; })
            .attr("transform", "translate(40,20)")
            .attr("font-size", 10)
            .attr("fill", "black")
        
        dayLabels.exit().remove();
        cardText.exit().remove();
    }

    let chartFinal = Object.assign(svg.node(), { value: null });
    chartFinal.update = update;
    return chartFinal;
}

export default HeatMapChart;